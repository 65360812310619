@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';
@import '../mixins/box-shadow';
@import '../mixins/link';

.preference {
  &-button {
    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      background-color: map-get($map: $colors, $key: background);
      padding: map-get($map: $spacing, $key: sitePadding) / 5;
      border-radius: map-get($map: $spacing, $key: borderRadius);
      margin: (map-get($map: $spacing, $key: sitePadding) * 2) 0;
    }

    &--clear {
      @include type($size: small, $color: copy);
      background-color: transparent;
      border: none;
    }

    &-wrapper {
      margin: map-get($map: $spacing, $key: sitePadding);
    }
  }

  &-checkbox {
    margin-bottom: map-get($map: $spacing, $key: sitePadding) * 1.5;
  }
}

.list {
  &-item {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: map-get($map: $spacing, $key: sitePadding);
      padding: map-get($map: $spacing, $key: sitePadding) / 2;
      border-radius: map-get($map: $spacing, $key: borderRadius);
      @include box-shadow(true);
    }

    &-image {
      border-radius: map-get($map: $spacing, $key: borderRadius);
    }

    &-shelfIcon {
      border-radius: map-get($map: $spacing, $key: borderRadius);
      background-color: map-get($map: $colors, $key: background);
    }

    &-plusIcon {
      width: 4.3rem;
      height: 4.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: map-get($map: $spacing, $key: borderRadius);
      background-color: map-get($map: $colors, $key: background);

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      h3 {
        @include type($size: base, $font: copyBold, $color: copyDark);
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  &-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    gap: 1rem;
  }

  &-text {
    @include type($size: small, $font: copy, $color: copy);
    text-align: center;

    &-bold {
      @include type($size: small, $font: copyBold, $color: copyDark);
    }

    &-link {
      @include link();
    }
  }
}

.createShelf {
  &-input {
    margin-bottom: map-get($map: $spacing, $key: sitePadding) * 1.5;
  }

  &-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &-preference {
    margin: map-get($map: $spacing, $key: sitePadding) * 2 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    span {
      @include type($size: base, $font: copySemiBold, $color: copyDark);
    }

    &-subtext {
      @include type($size: small, $font: copy, $color: copy);
      line-height: 1.5;
      margin-top: 0.25rem;
      margin-left: 1.75rem;
    }
  }
}

.privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    @include heading();
    text-align: center;
  }

  p {
    @include type($size: base, $font: copy, $color: copy);
    line-height: 1.5;
    margin: 1.5rem;
    text-align: center;
  }

  &-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
}

.share {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &-button {
    min-width: 8rem;
  }
}